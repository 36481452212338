<template>
  <date-range-picker
    ref="picker"
    :locale-data="localeData"
    v-model="dateRange"
    :ranges="ranges"
    :show-dropdowns="true"
    :timePicker="timePicker"
    :timePickerIncrement="1"
    :timePickerSeconds="true"
    opens="right"
    :minDate="minDate"
    :maxDate="maxDate"
  />
</template>

<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment/moment";
export default {
  name: "DateRangePickerWrapper",
  components: { DateRangePicker },
  props: {
    startDate: {
      type: Date,
    },
    endDate: {
      type: Date,
    },
    timePicker: {
      type: Boolean,
      default: true,
    },
    formatDate: {
      type: String,
      default: "dd.mm.yyyy HH:MM:ss",
    },
    minDate: {
      type: Date | null,
      default: null,
    },
    maxDate: {
      type: Date | null,
      default: null,
    },
  },
  computed: {
    ranges: () => {
      return {
        Сьогодні: [
          moment().startOf("day").toDate(),
          moment().endOf("day").toDate(),
        ],
        Вчора: [
          moment().subtract(1, "day").startOf("day").toDate(),
          moment().subtract(1, "day").endOf("day").toDate(),
        ],
        "Поточний місяць": [
          moment().startOf("month").toDate(),
          moment().endOf("month").toDate(),
        ],
        "Минулий місяць": [
          moment().subtract(1, "month").startOf("month").toDate(),
          moment().subtract(1, "month").endOf("month").toDate(),
        ],
        "Поточний квартал": [
          moment().startOf("quarter").toDate(),
          moment().endOf("quarter").toDate(),
        ],
        "Минулий квартал": [
          moment().subtract(1, "quarter").startOf("quarter").toDate(),
          moment().subtract(1, "quarter").endOf("quarter").toDate(),
        ],
        "Поточний рік": [
          moment().startOf("year").toDate(),
          moment().endOf("year").toDate(),
        ],
        "Минулий рік": [
          moment().subtract(1, "year").startOf("year").toDate(),
          moment().subtract(1, "year").endOf("year").toDate(),
        ],
      };
    },
    localeData() {
      return {
        format: this.formatDate,
        applyLabel: "Встановити",
        cancelLabel: "Відмінити",
        daysOfWeek: ["Нд", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
        monthNames: [
          "Січ",
          "Лют",
          "Бер",
          "Квіт",
          "Трав",
          "Черв",
          "Лип",
          "Серп",
          "Вер",
          "Жовт",
          "Лист",
          "Груд",
        ],
        firstDay: 1,
      };
    },
    dateRange: {
      get: function () {
        return {
          startDate: this.startDate,
          endDate: this.endDate,
        };
      },
      set: function (v) {
        this.$emit("start-date-change", v.startDate);
        this.$emit("end-date-change", v.endDate);
      },
    },
    activeRange() {
      const start = this.startDate.getTime();
      const end = this.endDate.getTime();
      for (let range in this.ranges) {
        let [rangeStart, rangeEnd] = this.ranges[range];
        if (rangeStart.getTime() === start && rangeEnd.getTime() === end) {
          return range;
        }
      }
      return null;
    },
  },
};
</script>

<style scoped></style>
